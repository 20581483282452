.table-row {
  background-color: var(--ql-color-white);
}


.ant-table-thead {
  background-color: var(--ql-color-white);
}

.ant-pagination-item {
  background-color: var(--ql-color-white);
}