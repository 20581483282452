:root {
  --ql-font-family-main: 'Open Sans', sans-serif;
  --ql-font-family-headings: 'Lato', sans-serif;

  --header-padding-top: 12px;
  --header-padding-bottom: 12px;
  --header-box-shadow: none;
  --header-border-bottom-style: solid;
  --header-border-bottom-width: 1px;

  /* Borders */
  --border-width-default: 1px;
  --border-style-default: solid;

  /* Font-sizes */
  --ql-font-size-xxs: 10px;
  --ql-font-size-xs: 12px;
  --ql-font-size-sm: 14px;
  --ql-font-size-default: 16px;
  --ql-font-size-lg: 20px;
  --ql-font-size-xl: 24px;
  --ql-font-size-xxl: 32px;
  --ql-font-size-xxxl: 40px;
  --ql-font-size-xxxxl: 48px;

  /* Line-heights */
  --ql-line-height-xxs: 12px;
  --ql-line-height-xs: 16px;
  --ql-line-height-sm: 20px;
  --ql-line-height-default: 24px;
  --ql-line-height-lg: 28px;
  --ql-line-height-xl: 32px;
  --ql-line-height-xxl: 40px;
  --ql-line-height-xxxl: 48px;
  --ql-line-height-xxxxl: 64px;

  /* Typography */
  /* font-weights are the same as in qonsoll */
  --ql-typography-heading-font-weight: var(--ql-font-weight-medium);
  --ql-font-family-main: 'Open Sans', sans-serif;
  --ql-font-family-headings: 'Lato', sans-serif;
  /* fs */
  --ql-font-size-h1: var(--ql-font-size-xxl);
  --ql-font-size-h2: var(--ql-font-size-xl);
  --ql-font-size-h3: var(--ql-font-size-lg);
  /* --ql-font-size-h4: var(--ql-font-size-lg);   // do not use
  --ql-font-size-h5: var(--ql-font-size-lg);
  --ql-font-size-h6:  var(--ql-font-size-lg); */
  --ql-font-size-overline: var(--ql-font-size-xxs);
  --ql-font-size-body1: var(--ql-font-size-default);
  --ql-font-size-body2: var(--ql-font-size-sm);
  --ql-font-size-caption1: var(--ql-font-size-xs);
  --ql-font-size-caption2: var(--ql-font-size-xxs);
  /* lh */
  --ql-line-height-h1: var(--ql-line-height-xxl);
  --ql-line-height-h2: var(--ql-line-height-xl);
  --ql-line-height-h3: var(--ql-line-height-lg);
  /* --ql-line-height-h4: var(--ql-line-height-lg);   // do not use
  --ql-line-height-h5: var(--ql-line-height-lg);
  --ql-line-height-h6: var(--ql-line-height-lg); */
  --ql-line-height-overline: var(--ql-line-height-xxs);
  --ql-line-height-body1: var(--ql-line-height-default);
  --ql-line-height-body2: var(--ql-line-height-sm);
  --ql-line-height-caption1: var(--ql-line-height-xs);
  --ql-line-height-caption2: var(--ql-line-height-xxs);

  /* ls */
  --ql-letter-spacing-default: 1%;
  /* Layout */
  --ql-general-layout-px-xxs: 16px; /* mobile (portrait): 0 - 576px */

  /* Aside */
  --ql-aside-padding: 24px;

  /* Card */
  --card-radius: 12px;

  /* Menu */
  --ql-menu-group-title-font-weight: var(--ql-font-weight-semibold);
  --ql-menu-group-title-padding: 8px 24px;
  --ql-menu-item-icon-margin-right: 16px;
  --ql-menu-bg: var(--ql-bg-white-default);
  --ql-menu-box-shadow: 0 0 0 1px var(--ql-border-dark-lighten5);
  --ql-menu-collapse-btn-border-default: 1px solid
    var(--ql-border-dark-lighten5);
  --ql-menu-collapse-btn-border-radius: 6px;
  --ql-menu-collapse-btn-border-left: 1px solid var(--ql-color-white);
  --ql-menu-collapse-btn-bg: var(--ql-color-white);
  --ql-menu-width-default: 240px;
  --ql-menu-width-collapsed: 98px;

  /* Account */
  --ql-form-item-vertical-spacing-without-message: 24px;

  /* Card */
  --ql-card-border-default: 1px solid transparent;

  /* Grid */
  --ql-grid-gutter: var(--ql-grid-gutter-sm);
  --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-sm) * -1);

  /* Select */
  --ql-select-border: none;

  /* Input */
  --ql-input-border-radius: 24px;

  /* Tag */
  --tag-border-radius: 8px;
  --ql-select-multiple-item-border-radius: var(--tag-border-radius);

  /* Button */
  --btn-border-radius-sm: 12px;
  --btn-border-radius-md: 20px;
  --btn-border-radius-lg: 28px;

  /* Collapse */
  --collapse-header-arrow-padding: 0 8px 0 0;

  /* Dropdown */
  --dropdown-menu-border-radius: 20px;

  /* EnumSelect */
  --enum-select-border-radius: 24px;

  /* Page height to display tables properly */
  --page-height-to-negotiate: 190px;
  --page-height-to-negotiate-with-table-header: 250px;
  --drinks-page-height-to-negotiate: 384px;
}

/* Grid (breakpoints from @qonsoll/react-design) */
@media (min-width: 576px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-sm);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-sm) * -1);
  }
}
@media (min-width: 768px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-md);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-md) * -1);
  }
}
@media (min-width: 992px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-lg);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-lg) * -1);
  }
}
@media (min-width: 1200px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-xl);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-xl) * -1);
  }
}
@media (min-width: 1600px) {
  :root {
    --ql-grid-gutter: var(--ql-grid-gutter-xxl);
    --ql-grid-gutter-negative: calc(var(--ql-grid-gutter-xxl) * -1);
  }
}
