.wrapper {
  border-color: var(--ql-border-dark-lighten5);
  border-radius: 28px;
  width: 216px;
}
.right-button {
  border-radius: 0px 20px 20px 0px;
}

.center-button {
  border-radius: 0;
}

.left-button {
  border-radius: 20px 0px 0px 20px;
}

.button-disabled-styles:focus {
  outline: none !important;
  box-shadow: none !important;
}
.button-disabled-styles:hover {
  outline: none !important;
  box-shadow: none !important;
}
