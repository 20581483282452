:root {
  /* --------------------------------------------------------COLORS */
  /* brand colors */
  --brand-accent1: hsla(334, 75.5%, 50.5%);
  --brand-accent2: hsla(219, 67.5%, 32.5%, 1);
  --brand-accent3: hsla(22, 82.8%, 58%, 1);
  --brand-accent4: hsla(276, 45%, 25.5%, 1);
  /* accent1 (primary) */
  --ql-color-accent1-darken: hsla(334, 72%, 41.8%, 1);
  --ql-color-accent1: hsla(334, 75.5%, 50.5%, 1);

  --ql-color-accent1-t-lighten2: hsla(334, 75.5%, 50.5%, 0.56);

  --ql-color-accent1-t-lighten5: hsla(334, 75.5%, 50.5%, 0.24);
  --ql-color-accent1-t-lighten6: hsla(
    334,
    75.5%,
    50.5%,
    0.06
  ); /* this is lighten7 from figma*/

  /* accent2: blue */
  --ql-color-accent2: hsla(219, 67.5%, 32.5%, 1);

  /* system */
  --ql-color-white: hsla(0, 0%, 100%, 1);

  --ql-color-dark: hsla(0, 0%, 6.5%, 1);
  --ql-color-dark-t-lighten1: hsla(0, 0%, 6.5%, 0.73);
  --ql-color-dark-t-lighten2: hsla(0, 0%, 6.5%, 0.61);
  --ql-color-dark-t-lighten3: hsla(0, 0%, 6.5%, 0.49);
  --ql-color-dark-t-lighten4: hsla(0, 0%, 6.5%, 0.32);
  --ql-color-dark-t-lighten5: hsla(0, 0%, 6.5%, 0.18);
  --ql-color-dark-t-lighten6: hsla(0, 0%, 6.5%, 0.1);
  --ql-color-dark-t-lighten7: hsla(0, 0%, 6.5%, 0.05);

  --ql-color-success: hsla(133, 76.5%, 30.5%, 1);
  --ql-color-warning: hsla(37, 86%, 59%, 1);
  --ql-color-danger-darken: hsla(349, 77%, 39.4%, 1);
  --ql-color-danger: hsla(349, 80%, 47.5%, 1);
  --ql-color-danger-t-lighten5: hsla(349, 80%, 47.5%, 0.2);
  --ql-color-danger-t-lighten6: hsla(349, 80%, 47.5%, 0.07);

  /* --------------------------------------------------------MEDIATORS */
  /* Backgrounds */
  --ql-bg-dark-default: var(--ql-color-dark);
  --ql-bg-dark-lighten3: var(--ql-color-dark-t-lighten3);
  --ql-bg-dark-lighten4: var(--ql-color-dark-t-lighten4);
  --ql-bg-dark-lighten5: var(--ql-color-dark-t-lighten5);
  --ql-bg-dark-lighten6: var(--ql-color-dark-t-lighten6);
  --ql-bg-dark-lighten7: var(--ql-color-dark-t-lighten7);
  --ql-bg-white-default: var(--ql-color-white);
  --ql-bg-accent1-darken: var(--ql-color-accent1-darken);
  --ql-bg-accent1-default: var(--ql-color-accent1);
  --ql-bg-accent1-lighten2: var(--ql-color-accent1-t-lighten2);
  --ql-bg-accent1-lighten6: var(--ql-color-accent1-t-lighten6);
  --ql-bg-accent2-default: var(--ql-color-accent2);
  --ql-bg-danger-darken: var(--ql-color-danger-darken);
  --ql-bg-danger-default: var(--ql-color-danger);
  --ql-disabled-bg: var(--ql-bg-dark-lighten6);
  --ql-bg-danger-lighten6: var(--ql-color-danger-t-lighten6);
  --ql-bg-disabled: var(--ql-bg-dark-lighten6);

  /* Borders */
  --ql-border-dark-default: var(--ql-color-dark);
  --ql-border-white-default: var(--ql-color-white);
  --ql-border-dark-lighten1: var(--ql-color-dark-t-lighten1);
  --ql-border-dark-lighten5: var(--ql-color-dark-t-lighten5);
  --ql-border-dark-lighten6: var(--ql-color-dark-t-lighten6);
  --ql-border-accent1-dark: var(--ql-color-accent1-darken);
  --ql-border-accent1-default: var(--ql-color-accent1);
  --ql-border-accent1-lighten5: var(--ql-color-accent1-t-lighten5);
  --ql-border-danger-darken: var(--ql-color-danger-darken);
  --ql-border-danger-default: var(--ql-color-danger);
  --ql-border-danger-lighten5: var(--ql-color-danger-t-lighten5);

  /* Typography */
  --ql-text-primary: var(--ql-color-dark);
  --ql-text-secondary: var(--ql-color-dark-t-lighten1);
  --ql-text-placeholder: var(--ql-color-dark-t-lighten2);
  --ql-text-disabled: var(--ql-color-dark-t-lighten3);
  --ql-text-white: var(--ql-color-white);
  --ql-text-accent1: var(--ql-color-accent1);
  --ql-text-accent1-disabled: var(--ql-color-accent1-t-lighten1);
  --ql-text-accent2: var(--ql-color-accent2);
  --ql-text-danger: var(--ql-color-danger);
  --ql-text-success: var(--ql-color-success);

  /* Shadow */
  --ql-outline-accent1-default: 0 0 0px 4px var(--ql-border-accent1-lighten5);
  --ql-outline-danger-default: 0 0 0px 4px var(--ql-border-danger-lighten5);

  /* --------------------------------------------------------COMPONENTS */
  --ql-typography-heading-color: var(--ql-color-accent2);
  /* especially for ANTD */
  --ql-state-disabled-color: var(--ql-text-disabled);

  /* Layout */
  --header-border-bottom-color: var(--ql-bg-white-default);
  --header-background-color: var(--ql-bg-white-default);
  --ql-layout-background: var(--ql-bg-white-default);

  /* Button */
  --btn-outline-focus: 3px solid var(--ql-border-white-default);
  --btn-shadow-focus: 0 0 0 4px var(--ql-border-dark-default);

  --btn-primary-bg: var(--ql-bg-accent1-default);
  --btn-primary-color: var(--ql-text-white);
  --btn-primary-border: transparent;
  --btn-primary-shadow: transparent;

  --btn-primary-hover-bg: var(--ql-bg-accent1-default);
  --btn-primary-hover-color: var(--ql-text-white);
  --btn-primary-hover-border: transparent;
  --btn-primary-hover-shadow: var(--ql-outline-accent1-default);

  --btn-primary-active-bg: var(--ql-bg-accent1-darken);
  --btn-primary-active-color: var(--ql-text-white);
  --btn-primary-active-border: transparent;
  --btn-primary-active-shadow: var(--ql-outline-accent1-default);

  --btn-primary-focus-bg: var(--ql-bg-accent1-default);
  --btn-primary-focus-color: var(--ql-text-white);
  --btn-primary-focus-border: transparent;
  --btn-primary-focus-shadow: var(--btn-shadow-focus);

  /* secondary */
  --btn-secondary-bg: var(--ql-bg-white-default);
  --btn-secondary-color: var(--ql-text-primary);
  --btn-secondary-border: var(--ql-border-dark-lighten5);
  --btn-secondary-shadow: transparent;

  --btn-secondary-hover-bg: var(--ql-bg-dark-lighten7);
  --btn-secondary-hover-color: var(--ql-text-primary);
  --btn-secondary-hover-border: var(--ql-border-dark-lighten5);
  --btn-secondary-hover-shadow: transparent;

  --btn-secondary-active-bg: var(--ql-bg-dark-lighten6);
  --btn-secondary-active-border: var(--ql-bg-dark-lighten5);
  --btn-secondary-active-color: var(--ql-text-primary);
  --btn-secondary-active-shadow: transparent;

  --btn-secondary-focus-bg: var(--ql-bg-white-default);
  --btn-secondary-focus-color: var(--ql-text-primary);
  --btn-secondary-focus-border: var(--ql-border-dark-lighten5);
  --btn-secondary-focus-shadow: var(--btn-shadow-focus);

  /* text */
  --btn-text-bg: transparent;
  --btn-text-color: var(--ql-text-accent1);
  --btn-text-border: transparent;
  --btn-text-shadow: transparent;

  --btn-text-hover-bg: var(--ql-bg-dark-lighten7);
  --btn-text-hover-color: var(--ql-text-accent1);
  --btn-text-hover-border: transparent;
  --btn-text-hover-shadow: transparent;

  --btn-text-active-bg: var(--ql-bg-dark-lighten6);
  --btn-text-active-border: transparent;
  --btn-text-active-color: var(--ql-text-accent1);
  --btn-text-active-shadow: transparent;

  --btn-text-focus-bg: transparent;
  --btn-text-focus-color: var(--ql-text-accent1);
  --btn-text-focus-border: transparent;
  --btn-text-focus-shadow: var(--btn-shadow-focus);

  /* danger-primary */
  --btn-primary-danger-bg: var(--ql-bg-danger-default);
  --btn-primary-danger-color: var(--ql-text-white);
  --btn-primary-danger-border: transparent;
  --btn-primary-danger-shadow: transparent;

  --btn-primary-danger-hover-bg: var(--ql-bg-danger-default);
  --btn-primary-danger-hover-color: var(--ql-text-white);
  --btn-primary-danger-hover-border: transparent;
  --btn-primary-danger-hover-shadow: var(--ql-outline-danger-default);

  --btn-primary-danger-active-bg: var(--ql-bg-danger-darken);
  --btn-primary-danger-active-color: var(--ql-text-white);
  --btn-primary-danger-active-border: transparent;
  --btn-primary-danger-active-shadow: var(--ql-outline-danger-default);

  --btn-primary-danger-focus-bg: var(--ql-bg-danger-default);
  --btn-primary-danger-focus-color: var(--ql-text-white);
  --btn-primary-danger-focus-border: transparent;
  --btn-primary-danger-focus-shadow: var(--btn-shadow-focus);

  /* danger-secondary */
  --btn-secondary-danger-bg: var(--ql-bg-white-default);
  --btn-secondary-danger-color: var(--ql-text-danger);
  --btn-secondary-danger-border: var(--ql-border-dark-lighten5);
  --btn-secondary-danger-shadow: transparent;

  --btn-secondary-danger-hover-bg: var(--ql-bg-dark-lighten7);
  --btn-secondary-danger-hover-color: var(--ql-text-danger);
  --btn-secondary-danger-hover-border: var(--ql-border-dark-lighten5);
  --btn-secondary-danger-hover-shadow: transparent;

  --btn-secondary-danger-active-bg: var(--ql-bg-dark-lighten6);
  --btn-secondary-danger-active-border: var(--ql-bg-dark-lighten5);
  --btn-secondary-danger-active-color: var(--ql-text-danger);
  --btn-secondary-danger-active-shadow: transparent;

  --btn-secondary-danger-focus-bg: var(--ql-bg-white-default);
  --btn-secondary-danger-focus-color: var(--ql-text-danger);
  --btn-secondary-danger-focus-border: var(--ql-border-dark-lighten5);
  --btn-secondary-danger-focus-shadow: var(--btn-shadow-focus);

  /* danger-text */
  --btn-text-danger-bg: transparent;
  --btn-text-danger-color: var(--ql-text-danger);
  --btn-text-danger-border: transparent;
  --btn-secondary-danger-shadow: transparent;

  --btn-text-danger-hover-bg: var(--ql-bg-dark-lighten7);
  --btn-text-danger-hover-color: var(--ql-text-danger);
  --btn-text-danger-hover-border: transparent;
  --btn-text-danger-hover-shadow: transparent;

  --btn-text-danger-active-bg: var(--ql-bg-dark-lighten6);
  --btn-text-danger-active-border: transparent;
  --btn-text-danger-active-color: var(--ql-text-danger);
  --btn-text-danger-active-shadow: transparent;

  --btn-text-danger-focus-bg: transparent;
  --btn-text-danger-focus-color: var(--ql-text-danger);
  --btn-text-danger-focus-border: transparent;
  --btn-text-danger-focus-shadow: var(--btn-shadow-focus);

  /* disabled-primary */
  --btn-primary-disabled-bg: var(--ql-bg-dark-lighten3);
  --btn-primary-disabled-color: var(--ql-text-white);
  --btn-primary-disabled-border: transparent;
  --btn-primary-disabled-shadow: transparent;

  /* disabled-secondary */
  --btn-secondary-disabled-bg: var(--ql-bg-dark-lighten7);
  --btn-secondary-disabled-color: var(--ql-text-disabled);
  --btn-secondary-disabled-border: var(--ql-bg-dark-lighten6);
  --btn-secondary-disabled-shadow: transparent;

  /* disabled-text */
  --btn-text-disabled-bg: var(--ql-bg-white-default);
  --btn-text-disabled-color: var(--ql-text-disabled);
  --btn-text-disabled-border: transparent;
  --btn-text-disabled-shadow: transparent;

  /* Input */
  --input-bg: var(--ql-bg-white-default);
  --input-placeholder-color: var(--ql-text-placeholder);
  --input-number-handler-bg: var(--ql-bg-accent1-default);
  --input-disabled-bg: var(--ql-bg-dark-lighten7);
  --input-disabled-color: var(--ql-text-disabled);
  --input-disabled-border: var(--ql-border-dark-lighten6);
  --input-color: var(--ql-text-primary);

  --input-border: var(--ql-border-dark-lighten5);

  --input-hover-border: var(--ql-border-accent1-default);

  --input-active-border: var(--ql-border-dark-lighten5);

  --input-focus-border: var(--ql-border-accent1-default);
  --input-focus-shadow: var(--ql-outline-accent1-default);

  --input-danger-border: var(--ql-border-danger-default);
  --input-danger-shadow: var(--ql-outline-danger-default);

  /* Select */
  --select-bg: var(--ql-bg-white-default);
  --select-border: var(--ql-border-dark-lighten5);
  --select-color: var(--ql-text-primary);
  --select-placeholder: var(--ql-text-placeholder);

  --select-hover-border: var(--ql-border-accent1-default);
  --select-hover-arrow-color: var(--ql-text-accent1);

  --select-active-border: var(--ql-border-dark-lighten5);
  --select-active-arrow-color: var(--ql-text-accent1);

  --select-focus-border: var(--ql-border-accent1-default);
  --select-focus-arrow-color: var(--ql-text-accent1);
  --select-focus-shadow: var(--ql-outline-accent1-default);

  --select-danger-border: var(--ql-border-danger-default);
  --select-danger-shadow: var(--ql-outline-danger-default);

  --select-disabled-bg: var(--ql-bg-dark-lighten7);
  --select-disabled-color: var(--ql-text-disabled);
  --select-disabled-border: var(--ql-border-dark-lighten6);

  /* Link */
  --breadcrumb-link-color-hover: var(--ql-text-color-accent1-lighten1);

  /* Popover */
  --popover-bg: var(--ql-bg-white-default);

  /* Dropdown */
  --dropdown-menu-bg: var(--ql-bg-white-default);
  --dropdown-item-color: var(--ql-typography-text-color-primary);
  --dropdown-item-default-bg-hover: var(--ql-bg-dark-lighten5);
  --dropdown-item-danger-bg-hover: var(--ql-bg-dark-lighten5);

  /* Message */
  --message-notice-content-bg: var(--ql-bg-white-default);
  --notification-bg: var(--ql-bg-white-default);

  /* Divider */
  --divider-color: var(--ql-border-dark-lighten5);

  /* Tag */
  --tag-default-bg: var(--ql-bg-white-default);
  --tag-default-border-color: var(--ql-border-dark-lighten5);

  /* Wrappers */
  --pagination-item-link-bg: var(--ql-bg-white-default);

  /* Table */
  --pagination-item-link-bg: var(--ql-bg-white-default);
  --table-header-bg: var(--ql-bg-white-default);
  --table-row-hover-bg: var(--ql-bg-dark-lighten7);
  --table-static-col-shadow: 8px 0 24px 0px var(--ql-border-dark-lighten6);

  /* Modal */
  --ql-modal-body-bg: var(--ql-bg-white-default);

  /* Country */
  --pyp-country-background: var(--ql-bg-white-default);
  --pyp-country-active: var(--ql-bg-dark-lighten4);
  --pyp-country-item: var(--ql-bg-dark-lighten4);
  --pyp-country-divider: var(--ql-border-dark-lighten1);

  /* Telephone */
  --pyp-telephone-br-color: var(--ql-color-accent1);
  --pyp-telephone-background: var(--ql-bg-white-default);

  /* Company */
  --pyp-company-box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px,
    rgba(0, 0, 0, 0.05) 0px 2px 4px, rgba(0, 0, 0, 0.05) 0px 2px 4px,
    rgba(0, 0, 0, 0.04) 0px 6px 8px, rgba(0, 0, 0, 0.04) 0px 8px 10px,
    rgba(0, 0, 0, 0.04) 0px 8px 10px;

  /* Telephone */
  --pyp-telephone-br-color: var(--ql-color-accent1);

  /* Radio */
  --ql-radio-hover-border: var(--input-hover-border-color);

  /* Icon */
  --ql-icon-color-white: var(--ql-color-white);
  --ql-icon-color-default: var(--ql-typography-text-color-primary);
  --ql-icon-color-secondary: var(--ql-typography-text-color-secondary);

  /* Menu */
  --ql-menu-item-color: var(--ql-typography-text-color-primary);
  --ql-menu-group-title-color: var(--ql-typography-text-color-primary);

  /* Account */
  --ql-account-color: var(--ql-typography-text-color-primary);
  --ql-account-avatar-box-shadow-hover: inset 0 0 0 2px var(--ql-color-accent1);

  /* Checkbox */
  --checkbox-checked-bg: var(--ql-bg-accent1-default);

  /* Ribbon */
  --ribbon-disabled-bg: var(--ql-bg-dark-lighten3);
  --ribbon-active-bg: var(--ql-bg-accent1-default);
  --ribbon-success-bg: var(--ql-color-success);

  /* EnumSelect */
  --ql-enum-select-border-color: var(--ql-border-dark-lighten5);

  /* Scroll */
  --scroll-thumb-color: var(--ql-border-dark-lighten5);

  /* Collapse */
  --collapse-header-bg: var(--ql-bg-dark-lighten4);

  /* Switcher */
  --switch-bg: var(--ql-bg-white-default);
  --switch-border: var(--ql-border-dark-lighten5);
  --switch-dot-default-bg: var(--ql-bg-dark-lighten4);
  --switch-dot-checked-bg: var(--ql-bg-accent1-default);

  --switch-hover-border: var(--ql-border-accent1-default);
  --switch-hover-shadow: var(--ql-outline-accent1-default);
  --switch-dot-default-hover-bg: var(--ql-bg-dark-lighten4);
  --switch-dot-checked-hover-bg: var(--ql-bg-accent1);

  --switch-active-border: var(--ql-border-dark-lighten5);
  --switch-active-shadow: none;
  --switch-dot-default-active-bg: var(--ql-bg-dark-lighten3);
  --switch-dot-checked-active-bg: var(--ql-bg-accent1-darken);

  --switch-focus-border: var(--ql-border-accent1-default);
  --switch-focus-shadow: var(--ql-outline-accent1-default);
  --switch-dot-default-focus-bg: var(--ql-bg-dark-lighten4);
  --switch-dot-checked-focus-bg: var(--ql-bg-accent1);

  --switch-disabled-bg: var(--ql-bg-dark-lighten7);
  --switch-disabled-border: var(--ql-bg-dark-lighten6);
  --switch-dot-default-disabled-bg: var(--ql-bg-dark-lighten5);
  --switch-dot-checked-disabled-bg: var(--ql-bg-accent1-lighten2);

  /* Checkbox */
  --checkbox-bg: var(--ql-bg-white-default);
  --checkbox-border: var(--ql-border-dark-lighten5);
  --checkbox-checked-border: transparent;

  --checkbox-hover-border: var(--ql-border-accent1-default);
  --checkbox-checked-hover-border: transparent;
  --checkbox-hover-shadow: var(--ql-outline-accent1-default);

  --checkbox-active-border: var(--ql-border-dark-lighten5);
  --checkbox-checked-active-border: transparent;
  --checkbox-active-shadow: none;

  --checkbox-focus-border: var(--ql-border-accent1-default);
  --checkbox-checked-focus-border: transparent;
  --checkbox-focus-shadow: var(--ql-outline-accent1-default);

  --checkbox-disabled-bg: var(--ql-bg-dark-lighten7);
  --checkbox-checked-disabled-bg: var(--ql-bg-accent1-lighten2);
  --checkbox-disabled-border: var(--ql-bg-dark-lighten6);

  /* Radio */
  --radio-bg: var(--ql-bg-white-default);
  --radio-border: var(--ql-border-dark-lighten5);
  --radio-checked-border: var(--ql-border-accent1-default);

  --radio-hover-border: var(--ql-border-accent1-default);
  --radio-checked-hover-border: var(--ql-border-accent1-default);
  --radio-hover-shadow: var(--ql-outline-accent1-default);

  --radio-active-border: var(--ql-border-dark-lighten5);
  --radio-checked-active-border: var(--ql-border-accent1-default);
  --radio-active-shadow: none;

  --radio-focus-border: var(--ql-border-accent1-default);
  --radio-checked-focus-border: var(--ql-border-accent1-default);
  --radio-focus-shadow: var(--ql-outline-accent1-default);

  --radio-disabled-bg: var(--ql-bg-dark-lighten7);
  --radio-checked-disabled-bg: var(--ql-bg-accent1-lighten2);
  --radio-disabled-border: var(--ql-bg-dark-lighten6);

  /* Option  */
  --option-bg: var(--ql-bg-white-default);
  --option-color: var(--ql-text-secondary);
  --option-border: var(--ql-border-dark-lighten5);
  --option-checked-border: var(--ql-border-accent1-default);
  --option-shadow: transparent;

  --option-hover-bg: var(--ql-bg-white-default);
  --option-hover-color: var(--ql-text-primary);
  --option-hover-border: var(--ql-border-accent1-default);
  --option-checked-hover-border: var(--ql-border-accent1-default);
  --option-hover-shadow: var(--ql-outline-accent1-default);

  --option-active-bg: var(--ql-bg-white-default);
  --option-active-color: var(--ql-text-primary);
  --option-active-border: var(--ql-border-dark-lighten5);
  --option-checked-active-border: var(--ql-border-dark-lighten5);
  --option-active-shadow: transparent;

  --option-focus-bg: var(--ql-bg-white-default);
  --option-focus-color: var(--ql-text-secondary);
  --option-focus-border: var(--ql-border-accent1-default);
  --option-checked-focus-border: var(--ql-border-accent1-default);
  --option-focus-shadow: var(--ql-outline-accent1-default);

  --option-disabled-bg: var(--ql-bg-dark-lighten7);
  --option-checked-disabled-bg: var(--ql-bg-accent1-lighten6);
  --option-disabled-border: var(--ql-border-dark-lighten6);
  --option-checked-disabled-border: var(--ql-border-accent1-lighten5);
  --option-disabled-color: var(--ql-text-disabled);

  /* Collapse */
  --collapse-header-bg: var(--table-header-bg);

  /* EnumSelect */
  --enum-select-bg: var(--ql-bg-white-default);
  --enum-select-color: var(--ql-text-secondary);
  --enum-select-border: var(--ql-border-dark-lighten5);

  --enum-select-disabled-bg: var(--ql-bg-dark-lighten7);
  --enum-select-disabled-color: var(--ql-text-disabled);

  --enum-select-selected-bg: var(--ql-bg-accent1-default);
  --enum-select-selected-color: var(--ql-text-white);
  --enum-select-selected-disabled-bg: var(--ql-bg-accent1-darken);
}
