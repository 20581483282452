@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@100;200;300;400;500;600;700;900&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('vars.css');
@import url('theme.css');
@import url('googleButton.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Vidaloka&display=swap');
@import url('reset.css');

#root {
  height: 100%;
}
body {
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
body::-webkit-scrollbar {
  width: 0.5rem;
  background: transparent;
}
body::-webkit-scrollbar-thumb {
  width: 0.5rem;
  background: transparent;
}

body::-webkit-scrollbar-track {
  width: 0.5rem;
  background: transparent;
}
#qonsoll-direct-layout-wrapper::-webkit-scrollbar {
  width: 0.5rem;
  background: transparent;
}
#qonsoll-direct-layout-wrapper::-webkit-scrollbar-thumb {
  width: 0.5rem;
  background: var(--scroll-thumb-color);
}
#qonsoll-direct-layout-wrapper::-webkit-scrollbar-track {
  width: 0.5rem;
  background: transparent;
}

h1 {
  margin: 0px 0px !important;
}

/* BUtton */
.ant-btn-sm {
  font-weight: var(--ql-font-weight-regular);
  border-radius: var(--btn-border-radius-sm);
}
.ant-btn {
  font-weight: var(--ql-font-weight-regular);
  border-radius: var(--btn-border-radius-lg);
}
.ant-btn-lg {
  font-weight: var(--ql-font-weight-regular);
  border-radius: var(--btn-border-radius-lg);
}

/* Button secondary in popover */
.ant-popover-buttons > button:not(.ant-btn-primary) {
  background-color: var(--btn-secondary-bg);
  border-color: var(--btn-secondary-border);
  color: var(--btn-secondary-color);
  box-shadow: var(--btn-secondary-shadow);
}
.ant-popover-buttons > button:not(.ant-btn-primary):hover {
  background-color: var(--btn-secondary-hover-bg);
  border-color: var(--btn-secondary-hover-border);
  color: var(--btn-secondary-hover-color);
  box-shadow: var(--btn-secondary-hover-shadow);
}
.ant-popover-buttons > button:not(.ant-btn-primary):active {
  background-color: var(--btn-secondary-active-bg);
  border-color: var(--btn-secondary-active-border);
  color: var(--btn-secondary-active-color);
  box-shadow: var(--btn-secondary-active-shadow);
}
.ant-popover-buttons > button:not(.ant-btn-primary):focus {
  background-color: var(--btn-secondary-focus-bg);
  border-color: var(--btn-secondary-focus-border);
  color: var(--btn-secondary-focus-color);
  outline: var(--btn-outline-focus);
  box-shadow: var(--btn-secondary-focus-shadow);
}

/* Button in LogOut modal*/
.ant-modal-confirm-btns > .ant-btn:not(.ant-btn-primary) {
  background-color: var(--btn-secondary-bg);
  border-color: var(--btn-secondary-border);
  color: var(--btn-secondary-color);
  box-shadow: var(--btn-secondary-shadow);
}
.ant-modal-confirm-btns > .ant-btn:not(.ant-btn-primary):hover {
  background-color: var(--btn-secondary-hover-bg);
  border-color: var(--btn-secondary-hover-border);
  color: var(--btn-secondary-hover-color);
  box-shadow: var(--btn-secondary-hover-shadow);
}
.ant-modal-confirm-btns > .ant-btn:not(.ant-btn-primary):active {
  background-color: var(--btn-secondary-active-bg);
  border-color: var(--btn-secondary-active-border);
  color: var(--btn-secondary-active-color);
  box-shadow: var(--btn-secondary-active-shadow);
}
.ant-modal-confirm-btns > .ant-btn:not(.ant-btn-primary):focus {
  background-color: var(--btn-secondary-focus-bg);
  border-color: var(--btn-secondary-focus-border);
  color: var(--btn-secondary-focus-color);
  outline: var(--btn-outline-focus);
  box-shadow: var(--btn-secondary-focus-shadow);
}

.ant-modal-confirm-btns > .ant-btn {
  background-color: var(--btn-primary-bg);
  border-color: var(--btn-primary-border);
  color: var(--btn-primary-color);
  box-shadow: var(--btn-primary-shadow);
}
.ant-modal-confirm-btns > .ant-btn:hover {
  background-color: var(--btn-primary-hover-bg);
  border-color: var(--btn-primary-hover-border);
  color: var(--btn-primary-hover-color);
  box-shadow: var(--btn-primary-hover-shadow);
}
.ant-modal-confirm-btns > .ant-btn:active {
  background-color: var(--btn-primary-active-bg);
  border-color: var(--btn-primary-active-border);
  color: var(--btn-primary-active-color);
  box-shadow: var(--btn-primary-active-shadow);
}
.ant-modal-confirm-btns > .ant-btn:focus {
  background-color: var(--btn-primary-focus-bg);
  border-color: var(--btn-primary-focus-border);
  color: var(--btn-primary-focus-color);
  outline: var(--btn-outline-focus);
  box-shadow: var(--btn-primary-focus-shadow);
}

/* Input with states */
.ant-input,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-picker {
  border-radius: var(--ql-input-border-radius);
  background: var(--input-bg);
  border: 1px solid var(--input-border);
  color: var(--input-color);
}
.ant-input:hover,
.ant-input-affix-wrapper:hover,
.ant-input-number:hover,
.ant-picker:hover {
  border: 1px solid var(--input-hover-border);
  box-shadow: none;
}
.ant-input:active,
.ant-input-affix-wrapper:active,
.ant-input-number:active,
.ant-picker:active {
  border: 1px solid var(--input-active-border);
  box-shadow: none;
}
.ant-input:focus {
  border: 1px solid var(--input-focus-border);
  box-shadow: var(--input-focus-shadow);
}
.ant-input-number:focus,
.ant-input-number-focused {
  border: 1px solid var(--input-focus-border) !important;
  box-shadow: var(--input-focus-shadow) !important;
}
.ant-input-affix-wrapper:focus :focus,
.ant-input-affix-wrapper-focused {
  border: 1px solid var(--input-focus-border) !important;
  box-shadow: var(--input-focus-shadow) !important;
}
.ant-picker:focus,
.ant-picker-focused {
  border: 1px solid var(--input-focus-border) !important;
  box-shadow: var(--input-focus-shadow) !important;
}
.ant-input[disabled],
.ant-input-number-disabled,
.ant-picker.ant-picker-disabled {
  background: var(--input-disabled-bg);
  border: 1px solid var(--input-disabled-border);
  color: var(--input-disabled-color);
}
.ant-input[disabled]:hover,
.ant-input-number-disabled:hover,
.ant-picker.ant-picker-disabled:hover {
  background: var(--input-disabled-bg);
  border: 1px solid var(--input-disabled-border);
  color: var(--input-disabled-color);
}
.ant-picker-input > input[disabled] {
  color: var(--input-disabled-color);
}
.ant-input:placeholder-shown {
  color: var(--input-placeholder-color);
}
/* PhoneInput */
.country-list {
  background-color: var(--ql-bg-white-default) !important;
}
.react-tel-input .country-list .search {
  background-color: var(--ql-bg-white-default) !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: var(--ql-bg-dark-lighten5) !important;
}
.react-tel-input .country-list .country {
  background-color: var(--ql-bg-white-default) !important;
}
.react-tel-input .country-list .search-box {
  border-radius: var(--ql-input-border-radius) !important;
  border-color: var(--input-border) !important;
}
.react-tel-input .country-list .search-box:hover {
  border: 1px solid var(--input-hover-border) !important;
  box-shadow: none !important;
}
.react-tel-input .country-list .search-box:active {
  border: 1px solid var(--input-active-border) !important;
  box-shadow: none !important;
}
.react-tel-input .country-list .search-box:focus {
  border: 1px solid var(--input-focus-border) !important;
  box-shadow: var(--input-focus-shadow) !important;
}
.react-tel-input .selected-flag:before {
  border-radius: var(--ql-input-border-radius) 0px 0px
    var(--ql-input-border-radius) !important;
}
.react-tel-input .flag-dropdown,
.react-tel-input .flag-dropdown:hover,
.react-tel-input .flag-dropdown:focus {
  border-radius: var(--ql-input-border-radius) 0px 0px
    var(--ql-input-border-radius) !important;
}
/* error input */
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input:active {
  border-color: var(--input-danger-border);
  background: var(--input-bg);
}
.ant-form-item-has-error .ant-input:focus {
  border-color: var(--input-danger-border);
  background: var(--input-bg);
  box-shadow: var(--input-danger-shadow);
}
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-input-number-focused {
  border-color: var(--input-danger-border) !important;
  background: var(--input-bg) !important;
  box-shadow: var(--input-danger-shadow) !important;
}
.ant-form-item-has-error .ant-picker:focus,
.ant-form-item-has-error .ant-picker-focused {
  border-color: var(--input-danger-border) !important;
  background: var(--input-bg) !important;
  box-shadow: var(--input-danger-shadow) !important;
}

.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background: var(--input-bg) !important;
}
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: var(--input-danger-border) !important;
  background: var(--input-bg) !important;
  box-shadow: var(--input-danger-shadow) !important;
}
.ant-form-item-explain.ant-form-item-explain-error {
  color: var(--ql-text-danger);
}
.ant-input-number-handler-wrap {
  border-radius: 0 var(--ql-input-border-radius) var(--ql-input-border-radius)
    0px;
  color: var(--ql-text-accent1);
  background: var(--ql-bg-white-default);
}
.ant-input-number-handler-up {
  border-top-right-radius: var(--ql-input-border-radius);
  color: var(--ql-text-accent1);
}
.ant-input-number-handler-down {
  border-bottom-right-radius: var(--ql-input-border-radius);
  color: var(--ql-text-accent1);
}
.ant-input-number-input {
  border-radius: 0px;
}
.ant-input-number-handler-wrap {
  border-left: none;
  background: var(--ql-bg-white-default);
}
.ant-input-number-handler-up-inner {
  margin-top: -1px;
}
.ant-input-number-handler-down {
  border-top: none;
}
.ant-input-number-handler-wrap
  .ant-input-number-handler
  .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap
  .ant-input-number-handler
  .ant-input-number-handler-down-inner {
  font-size: 9px;
}
.ant-input-number-handler-up:active,
.ant-input-number-handler-down:active {
  background: var(--ql-bg-dark-lighten7);
}

/* Select */
.ant-select {
  border-radius: var(--ql-input-border-radius);
  background: var(--select-bg);
  border: 1px solid var(--select-border);
  color: var(--select-color);
  transition: all var(--ql-animation-duration-slow);
}
.ant-select-selector {
  border-radius: var(--ql-input-border-radius) !important;
}
.ant-select-single .ant-select-selector {
  align-items: center;
}
.ant-select:hover {
  border: 1px solid var(--select-hover-border);
}
.ant-select:active {
  border: 1px solid var(--select-active-border);
}
.ant-select:focus,
.ant-select-focused {
  border: 1px solid var(--select-focus-border);
  box-shadow: var(--select-focus-shadow);
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled),
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):hover,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):active {
  border-color: var(--input-danger-border);
  background: var(--input-bg);
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):focus {
  border-color: var(--input-danger-border);
  background: var(--input-bg);
  box-shadow: var(--input-danger-shadow);
}
.ant-select-disabled.ant-select .ant-select-selector {
  background: var(--input-disabled-bg) !important;
  color: var(--input-disabled-color) !important;
}
.ant-select-disabled.ant-select:hover {
  background: transparent !important;
  color: var(--input-disabled-color) !important;
  border: 1px solid var(--select-border);
}
.ant-select-arrow {
  color: var(--ql-text-placeholder);
}
.ant-select:hover .ant-select-arrow,
.ant-select:active .ant-select-arrow,
.ant-select:focus .ant-select-arrow {
  color: var(--ql-text-accent1);
}
.ant-select-disabled.ant-select .ant-select-arrow,
.ant-select-disabled.ant-select:hover .ant-select-arrow {
  color: var(--ql-text-disabled);
}
.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100%;
}
.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 0px;
}
.ant-select-clear {
  width: 14px;
  height: 14px;
  background: var(--ql-bg-white-default);
}

/* Upload */
.ant-upload {
  border-radius: var(--ql-input-border-radius) !important;
  border: none !important;
}

/* Popover */
.ant-popover-inner-content {
  background-color: var(--ql-bg-white-default);
  border-radius: 6px;
}

/* Card */
.ant-card-body {
  box-shadow: none;
  border-radius: var(--card-radius);
}

/* Notification */
.ant-notification-notice-btn {
  float: none;
  display: flex;
  justify-content: flex-end;
}

/* Tag */
.ant-tag {
  border-radius: var(--tag-border-radius);
}
/* Link */
a:focus-visible {
  box-shadow: 0 0 0 2px var(--ql-border-dark-default);
  border-radius: 4px;
  text-decoration: none;
}
a:hover,
.ant-breadcrumb-link:hover {
  color: var(--ql-text-accent1);
}

/* Tabs */
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  border-radius: 50% 50% 0 0;
  height: 4px;
}

.ant-tabs-tab:hover {
  color: var(--ql-text-accent1);
}
.ant-tabs-tab:hover + .ant-tabs-ink-bar {
  background: var(--ql-bg-accent1-default);
  transition: all var(--ql-animation-duration-default)
    var(--ql-animation-cubic-ease-in-out);
}
.ant-tabs-tab:active + .ant-tabs-ink-bar {
  background: var(--ql-bg-accent1-darken);
  transition: all var(--ql-animation-duration-default)
    var(--ql-animation-cubic-ease-in-out);
}
.ant-tabs-tab:focus + .ant-tabs-ink-bar {
  background: var(--ql-bg-accent1-default);
  transition: all var(--ql-animation-duration-default)
    var(--ql-animation-cubic-ease-in-out);
  height: 8px !important;
}
.ant-tabs-tab-btn:focus-visible {
  outline: 2px solid var(--ql-color-white);
  box-shadow: 0 0 0 4px var(--ql-color-accent2);
  border-radius: 4px;
}

/* Dropdown Menu */
.ant-dropdown-menu {
  overflow: hidden;
}

/* Ribbon */
.ant-ribbon {
  z-index: 100;
  border-radius: 4px;
}

/* Ant Form */
.ant-form-item-label > label {
  font-size: var(--ql-font-size-body1);
  line-height: var(--ql-line-height-body1);
  color: var(--ql-text-primary);
  font-weight: var(--ql-font-weight-regular);
}
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  font-size: var(--ql-font-size-xl);
  align-self: end;
}

/* Animation reduce */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}
