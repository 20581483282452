@keyframes rotate {
  0% {
    -moz-transform:rotate(320deg);
    -webkit-transform:rotate(320deg);
    -o-transform:rotate(320deg);
    -ms-transform:rotate(320deg);
  }
}

.spin-logo {
  animation: rotate 0.5s infinite linear
}